* {
  box-sizing: border-box;
}

.category_create_edit {
  width: 600px !important;
}
.time-range-item-offer {
  .MuiListItem-button:hover {
    background-color: #E02443;
    color: white;
  }
  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #E02443;
    color: white;
  }
}
.time-range-item {
  .MuiListItem-button:hover {
    background-color: #E02443;
    color: white;
  }
  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #E02443;
    color: white;
  }
}
.layout {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  > div {
    width: 100%;
    max-width: 100%;
  }
  main {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 600px) {
  .layout {
    main {
      > div:nth-child(1) {
        width: 55px !important;
        max-width: 55px !important;
      }
      > div:nth-child(2) {
        width: calc(100% - 55px) !important;
        max-width: calc(100% - 55px) !important;
      }
    }
    &.open {
      main {
        > div:nth-child(1) {
          width: 240px !important;
          max-width: 240px !important;
        }
        > div:nth-child(2) {
          width: calc(100% - 240px) !important;
          max-width: calc(100% - 240px) !important;
        }
      }
    }
  }

  .sticky-toolbar {
    height: 72px;
    flex-wrap: nowrap;
  }

  .sticky-tab {
    top: 72px;
  }
}

@media (max-width: 599px) {
  #main-content {
    width: 100%;
    max-width: 100%;
  }

  .sticky-toolbar {
    flex-wrap: wrap;
    height: 100px;
  }

  .sticky-tab {
    top: 100px;
  }
}

@media (max-width: 350px) {
  #main-content {
    width: 100%;
    max-width: 100%;
  }

  .sticky-toolbar {
    flex-wrap: wrap;
    height: 190px;
    & > div {
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      height: 140px;
      & > div,
      & button {
        width: 100%;
        text-align: center;
        margin: 0;
      }
    }
  }

  .sticky-tab {
    top: 190px;
  }
}

.list-page {
  > div {
    display: block;
    width: 100%;
    max-width: 100%;
    &:first-child {
      .MuiFab-sizeSmall {
        width: 22px;
        height: 22px;
        min-height: 22px;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        position: static;
        // margin: 4px 5px;
        font-size: 0.8125rem;
        box-shadow: none;
        .MuiSvgIcon-root {
          font-size: 1.125rem;
        }
      }
      .MuiIconButton-sizeSmall {
        // margin: 4px 5px;
        padding: 0;
        width: 22px;
        height: 22px;
        min-height: 22px;
        .MuiSvgIcon-root {
          font-size: 1.5rem;
        }
      }
    }
  }
}
.table_products {
  .MuiTableCell-sizeSmall {
    padding-left: 0;
    &:nth-child(1),
    &:nth-child(2) {
      padding-right: 0;
    }
  }
}

.table-responsive {
  // width: calc(100% - 32px);
  min-height: 0.01%;
  overflow-x: auto;
  margin: 16px auto 0;
  padding: 0 0 6px;
  position: relative;
  .table {
    thead {
      tr {
        th {
          text-transform: capitalize;
          white-space: nowrap;
          padding: 6px 10px;
          &:nth-last-child(1) {
            background: #fff;
            min-width: 48px;
            // width: 48px;
            // max-width: 48px;
            position: relative;
            right: 0;
            z-index: 5;
          }
          &:nth-last-child(2) {
            background: #fff;
            min-width: 48px;
            // width: 48px;
            // max-width: 48px;
            position: relative;
            right: 0;
            z-index: 5;
            &::before {
              content: "";
              display: block;
              width: 5px;
              height: 100%;
              position: absolute;
              top: 0;
              left: -5px;
              z-index: 6;
              background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          white-space: nowrap;
          padding: 6px 10px;
          &:nth-last-child(1) {
            background: #fff;
            min-width: 48px;
            // width: 48px;
            // max-width: 48px;
            position: relative;
            right: 0;
            z-index: 5;
          }
          &:nth-last-child(2) {
            background: #fff;
            min-width: 48px;
            // width: 48px;
            // max-width: 48px;
            position: relative;
            right: 0;
            z-index: 5;
            &::before {
              content: "";
              display: block;
              width: 5px;
              height: 100%;
              position: absolute;
              top: 0;
              left: -5px;
              z-index: 6;
              background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
            }
          }
          .listButton {
            a,
            button {
              min-width: 32px;
              width: 32px;
              max-width: 32px;
            }
          }
        }
        &:hover {
          td {
            background: #f5f5f5;
            &:nth-last-child(1) {
              background: #f5f5f5;
            }
            &:nth-last-child(2) {
              background: #f5f5f5;
              &::before {
                background: -moz-linear-gradient(left, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
                background: -webkit-linear-gradient(left, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
                background: linear-gradient(to right, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f5f5f5', endColorstr='#f5f5f5',GradientType=1 );
              }
            }
          }
        }
      }
    }
  }
}
.list-page .sticky-first-column {
  .table {
    thead {
      tr {
        th {
          &:nth-child(1) {
            background: #fff;
            min-width: 150px;
            width: 150px;
            max-width: 150px;
            position: sticky;
            left: 0;
            z-index: 5;
          }
          &:nth-child(2) {
            background: #fff;
            min-width: 210px;
            width: 210px;
            max-width: 210px;
            position: sticky;
            left: 148px;
            z-index: 5;
            &::after {
              content: "";
              display: block;
              width: 5px;
              height: 100%;
              position: absolute;
              top: 0;
              right: -5px;
              z-index: 6;
              background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-child(1) {
            background: #fff;
            width: 150px;
            max-width: 150px;
            position: sticky;
            left: 0;
            z-index: 5;
          }
          &:nth-child(2) {
            background: #fff;
            width: 210px;
            max-width: 210px;
            position: sticky;
            left: 148px;
            z-index: 5;
            overflow-x: hidden;
            text-overflow: ellipsis;
            &::after {
              content: "";
              display: block;
              width: 5px;
              height: 100%;
              position: absolute;
              top: 0;
              right: -5px;
              z-index: 6;
              background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
            }
          }
        }
        &:hover {
          td {
            &:nth-child(1) {
              background: #f5f5f5;
            }
            &:nth-child(2) {
              background: #f5f5f5;
              &::after {
                background: -moz-linear-gradient(left, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                background: -webkit-linear-gradient(left, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                background: linear-gradient(to right, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#00f5f5f5',GradientType=1 );
              }
            }
          }
        }
      }
    }
  }
}

.list-page .sticky-three-buttons {
  .table {
    thead {
      tr {
        th {
          &:nth-last-child(2) {
            &::after {
              background: none;
              filter: none;
              width: 0px;
              position: static;
            }
          }
          &:nth-last-child(3) {
            background: #fff;
            min-width: 48px;
            width: 48px;
            max-width: 48px;
            position: sticky;
            right: 96px;
            z-index: 5;
            &::after {
              content: "";
              display: block;
              width: 5px;
              height: 100%;
              position: absolute;
              top: 0;
              right: -5px;
              z-index: 6;
              background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-last-child(2) {
            &::before {
              background: none;
              filter: none;
              width: 0px;
              position: static;
            }
          }
          &:nth-last-child(3) {
            background: #fff;
            min-width: 48px;
            width: 48px;
            max-width: 48px;
            position: sticky;
            right: 96px;
            z-index: 5;
            &::before {
              content: "";
              display: block;
              width: 5px;
              height: 100%;
              position: absolute;
              top: 0;
              left: -5px;
              z-index: 6;
              background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
            }
          }
        }
        &:hover {
          td {
            &:nth-last-child(2) {
              background: #f5f5f5;
              &::after {
                background: none;
                filter: none;
              }
            }
            &:nth-last-child(3) {
              background: #f5f5f5;
              &::after {
                background: -moz-linear-gradient(left, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                background: -webkit-linear-gradient(left, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                background: linear-gradient(to right, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#00f5f5f5',GradientType=1 );
              }
            }
          }
        }
      }
    }
  }
}

.list-page .sticky-action_btn {
  thead {
    tr {
      th {
        &:nth-last-child(1) {
          right: 0px;
          width: 48px;
          min-width: 48px;
          max-width: 164px;
        }
        &:nth-last-child(2) {
          position: static;
          width: none;
          max-width: none;
          min-width: 48px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        &:nth-last-child(1) {
          right: 0px;
          width: 48px;
          min-width: 48px;
          max-width: 164px;
        }
        &:nth-last-child(2) {
          position: static;
          width: none;
          max-width: none;
          min-width: 48px;
        }
      }
      .flex-container {
        margin-left: 10px;
        > div {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

form label {
  text-transform: capitalize;
}

.code {
  display: block;
  width: 100%;
  margin: 0 0 10px;
  padding: 0;
  overflow: hidden;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  font-size: 16px;
  line-height: 19px;
}

.product_number {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #080707;
  display: inline-block;
  vertical-align: top;
  width: auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  padding: 3px 8px 3px 26px;
  height: 26px;
  border-radius: 13px;
  border: 1px solid #dddbda;
  &::after {
    display: block;
    content: "";
    width: 15px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 7px;
    margin: -5px 0 0;
    background: transparent url("../svg/product_number.svg") no-repeat scroll 50% 50%;
    background-size: contain;
  }
}

.percent_box {
  width: 111px;
  & > div {
    background: #fff;
    padding-right: 0;
  }
  padding-right: 0;
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
    border: 1px solid #dddddd;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    right: 0;
    background: #f5f5f5;
  }
}
.select_box {
  .MuiSelect-select:focus {
    background: none;
  }
}
.product-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #e5e9eb;
  align-items: stretch;
  align-content: stretch;
  width: auto;
  min-height: 130px;

  & .product-row__avatar-info,
  & .product-row__clicker,
  & .product-row__price,
  & .product-row__btn-delete {
    align-self: center;
    flex: 0 1 auto;
  }

  & .product-row__avatar-info {
    width: calc(50% - 130px);
    max-width: calc(50% - 130px);
  }
  & .product-row__clicker {
    flex: 1 0 auto;
  }
  & .product-row__btn-delete {
    width: 26px;
    max-width: 26px;
  }
  & .product-row__price {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(50% - 110px);
    max-width: calc(50% - 110px);
    & div:first-child {
      width: 33.33333%;
    }
  }

  .product-row__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50px;
    max-width: 50px;
    flex: 0 1 auto;
  }
}

.quantity-box__quantity-element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 126px;
  background: #f7f8fa;
  .MuiIconButton-sizeSmall {
    height: 42px;
    width: 42px;
    border-radius: 0;
  }
}

.list-header {
  min-height: 36px;
  max-height: 36px;
  background: #e7e8ea;
  width: 100%;
  border-radius: 5px;
  line-height: 36px;
  & .MuiFormControlLabel-root {
    margin: 0;
  }
  & .MuiIconButton-sizeSmall {
    width: 50px;
    max-width: 50px;
  }
}

.footer-order-edit {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 83px;
  margin-top: 30px;
  & .footer-order-edit__total-price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(50% + 80px);
    height: 50px;
    padding: 0 20px;
    background: #f7f8fa;
    & div:first-child {
      flex: 0 1 auto;
      width: calc(33.33333% + 90px);
    }
  }

  .footer-order-edit__add-product {
    & .MuiButton-containedSizeSmall {
      padding: 0;
    }
    & .MuiButton-iconSizeSmall {
      width: 38px;
      height: 38px;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dddddd;
    }
    & .MuiButton-contained {
      background: #f7f8fa;
      text-transform: unset;
      height: 38px;
      min-width: 160px;
      justify-content: center;
      border: 1px solid #dddddd;
      & > span > span:first-child {
        flex: 1 1 auto;
      }
    }
  }
  .footer-order-edit__search {
    width: 35%;
    max-width: 35%;
    margin-top: 5px;
    & > div {
      margin: 0;
    }
    & > ul {
      background: #ffffff;
      border: 1px solid #efefef;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    }
    .search-product {
      display: flex;
      flex-wrap: wrap;
      .search-product__p-name {
        width: 80%;
      }
      .search-product__btn {
        height: 38px;
        width: 20%;
      }
      .search-product__price {
        font-weight: 500;
        color: #252c32;
      }
    }
  }
}
.dropdown {
  position: relative;
  max-width: 575px;
  width: 575px;
  .dropdown__selected-item {
    li {
      height: 84px;
      border: 1px solid #d8dddf;
      border-radius: 2px;
    }
  }
  .wrapper {
    position: absolute;
    width: 100%;
    top: 84px;
    left: 0px;
    max-height: 302px;
    z-index: 1000;
  }
  .dropdown__list {
    max-height: 252px;
    overflow-y: auto;
    border: 1px solid #d8dddf;
    border-radius: 2px;
    border-top: none;
    li {
      height: 84px;
      border-bottom: 1px solid #d8dddf;
      border-radius: 2px;
      background: #fff;
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .selected-item {
    background: #fff9f9;
  }
  .wrapper.closeList {
    max-height: 0;
    overflow: hidden;
  }
  .edit-btn {
    width: 80px;
    height: 40px;
    background: #fff;
    border: 1px solid #dddbda;
    border-radius: 5px;
    text-transform: capitalize;
  }
  .change-btn:hover {
    background: none;
  }
  .change-btn {
    width: 80px;
    height: 40px;
    border: none;
    color: #e02443;
    text-transform: uppercase;
  }
  .item-text {
    max-width: 360px;
  }
}
// @media (max-width: 1199px) {
//   .product-row {
//     flex-direction: column;
//   }
// }
.add-new-project-btn {
  height: 50px;
  line-height: 50px;
  background: #f7f8fa;
  position: sticky;
  bottom: 0;
  border: 1px solid #d8dddf;
  border-top: none;
  border-radius: 2px;
  & > button {
    text-transform: none;
    height: 100%;
    padding-left: 18px;
    padding-right: 24px;
    & > span > span {
      width: 40px;
    }
  }
}
.active_btn {
  transform: none;
  opacity: 1;
}
.supplier_number {
  opacity: 0.7;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #080707;
  display: inline-block;
  vertical-align: top;
  width: auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  padding: 3px 8px 3px 5px;
  height: 26px;
}

.MuiBox-root.table_style {
  padding: 0;
  margin: 0;
  .table-responsive {
    width: 100%;
  }
}
.delivery_box {
  padding: 25px 30px;
  border-bottom: 1px solid #e0e0e0;

  .placeholder-time {
    .MuiSelect-select.MuiSelect-select {
      color: #a3a3a3;
    }
  }

  .time-range1{
    width: 300px;

    & .MuiInput-underline:before {
      border-bottom: 1px solid red !important;
    }

    .MuiSelect-select.MuiSelect-select {
      border: 1px solid #d8dddf;
      border-radius: 4px;
      padding-top: 12px;
      text-align: end;
    }

    .MuiSelect-select:focus {
      background-color: white;
    }

    svg {
      left: 12px;

      :first-child {
        color: #9AA4B8;
      }
      :last-child {
        color: #E02443;
      }
    }
  }

  .time-range2{
    width: 100%;

    & .MuiInput-underline:before {
      border-bottom: 1px solid red !important;
    }

    .MuiSelect-select.MuiSelect-select {
      border: 1px solid #d8dddf;
      border-radius: 4px;
      padding-top: 12px;
      text-align: end;
    }
    .MuiSelect-select:focus {
      background-color: white;
    }

    svg {
      left: 12px;

      :first-child {
        color: #9AA4B8;
      }
      :last-child {
        color: #E02443;
      }
    }
  }
  & > p {
    margin: 0;
    padding-left: 38px;
    color: #252c32;
    opacity: 0.6;
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
    max-height: 25px;
  }
  .MuiFormControl-marginDense.MuiFormControl-fullWidth {
    margin-top: 20px;
  }
  .delivery_vehicle_box {
    background: #e7e8ea;
    width: 300px;
    height: 200px;
    padding: 20px 5px 20px 10px;
    & > p,
    & > div {
      position: relative;
      margin: 0;
      height: 20px;
      line-height: 20px;
      padding-left: 30px;
      &:nth-child(2),
      &:nth-child(4) {
        margin-bottom: 20px;
      }
      &:nth-child(3) {
        margin-bottom: 10px;
      }
      & > .icon {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        line-height: 20px;
      }
    }
    .MuiFormControlLabel-root {
      display: block;
      position: absolute;
      left: 0;
    }
    .listButton {
      display: inline-block;
      & .MuiButton-root {
        min-width: 0;
      }
      & button {
        padding: 0;
      }
    }
  }
}

.order_edit {
  .time_constraints {
    padding: 10px 0px;
    & > p {
      margin: 0;
      padding-left: 38px;
      color: #252c32;
      opacity: 0.6;
    }
    .MuiFormControlLabel-root {
      margin-left: 0;
      max-height: 25px;
    }
    .MuiFormControl-marginDense.MuiFormControl-fullWidth {
      margin-top: 20px;
    }
  }
  .box_size {
    max-width: 580px;
    min-width: 320px;
    width: 100%;
  }
  .placeholder-time {
    .MuiSelect-select.MuiSelect-select {
      color: #a3a3a3;
    }
  }
  .time-range{
    width: 300px;
    margin-top: 8px;

    & .MuiInput-underline:before {
      border-bottom: 1px solid red !important;
    }

    .MuiSelect-select.MuiSelect-select {
      height: 42px;
      border-radius: 4px;
      padding-top: 12px;
      padding-left: 195px;
    }

    .MuiSelect-select:focus {
      background-color: white;
    }

    svg {
      left: 12px;

      :first-child {
        color: #9AA4B8;
      }
      :last-child {
        color: #E02443;
      }
    }
  }
  .MuiFormControl-root.MuiFormControl-marginDense {
    width: 100%;
  }
  .MuiSelect-select.MuiSelect-select {
    height: 84px;
    border: 1px solid #d8dddf;
  }
  .MuiInput-formControl.MuiInputBase-marginDense.MuiInput-marginDense {
    &::after,
    &::before {
      border: none;
    }
  }

  .MuiSelect-root {
    box-sizing: border-box;
    display: flex;
    div {
      align-self: center;
      &:nth-child(2) {
        color: #252c32;
        span > p {
          &:nth-child(1) {
            color: #0c0c0c;
          }
        }
      }
    }
  }
  .MuiSelect-iconOutlined {
    transform: rotate(0deg);
    top: calc(50% - 25px);
    right: 40px;
    color: #e02443;
  }
  .accordion {
    border: none;
    box-shadow: none;
    padding: 0;

    .MuiAccordionSummary-root {
      min-height: 36px;
      max-height: 36px;
      background: #e7e8ea;
      width: 100%;
      border-radius: 5px;
      line-height: 36px;
    }
    .MuiAccordionSummary-content {
      margin: 0;
    }
    &.MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
    &::before {
      display: none;
    }
  }
  .comment.MuiFormControl-root.MuiFormControl-marginDense {
    width: 100%;
    border: 1px solid #d8dddf;
    min-height: 110px;
    box-sizing: border-box;
  }
  .MuiInputBase-multiline.MuiInputBase-marginDense {
    padding: 16px;
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
}

.dialog-title {
  max-height: 50px;
  height: 50px;
  padding: 0 24px;
  line-height: 50px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  background: #e02443;
  text-transform: none;
  .MuiTypography-root.MuiTypography-h6 {
    display: block;
    height: 50px;
    line-height: 50px;
    font-weight: 400;
    font-size: 18px;
  }
}
.MuiDialogTitle-root.dialog-title {
  padding: 0;
  line-height: 50px;
}
.bnt_alternative {
  width: 150px;
  max-width: 150px;
  height: 42px;
  padding: 0 0 0 25px;
  background: #fff;
  padding-left: 0;
  margin-left: 20px;
  opacity: 1;
  z-index: 100;
  &.MuiButton-outlinedSizeSmall,
  &.MuiButton-containedSecondary {
    margin-left: 20px;
  }
  .MuiButton-startIcon.MuiButton-iconSizeSmall {
    margin-right: 16px;
  }
  .Mui-disabled {
    color: #f50057;
  }
  &::first-letter {
    text-transform: capitalize;
  }
}
.bnt_not_alternative {
  width: 150px;
  max-width: 150px;
  height: 42px;
  padding: 0 0 0 25px;
  border: 1px solid #e7e8ea !important;
  color: #fff !important;
  background: #2d487e;
  padding-left: 0;
  margin-left: 20px;
  &.MuiButton-containedSizeSmall {
    margin-left: 20px;
  }
  .MuiButton-startIcon.MuiButton-iconSizeSmall {
    margin-right: 16px;
  }
}
.truck_boxes {
  .MuiFormControlLabel-root {
    flex-direction: column;
    width: 120px;
    height: 80px;
  }
}
.checkbox_products_list {
  height: 100%;
  top: 0;
  &:hover {
    background: none;
  }
}
.checkbox_products_list.alternative {
  position: absolute;
  top: -20px;
  position: absolute;
  background: #fafafa;
  text-align: center;
  line-height: 36px;
  border-radius: 4px;
  height: auto;
}
div.out_of_stock {
  background: #f3d3d8;
  position: absolute;
  bottom: 0;
}
ul.MuiList-root.MuiList-dense.MuiList-padding {
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
}

[dir="rtl"] {
  .list-page {
    .table-responsive {
      .table {
        thead {
          tr {
            th {
              &:nth-last-child(1) {
                right: auto;
                left: 0;
              }
              &:nth-last-child(2) {
                right: auto;
                left: 48px;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              white-space: nowrap;
              padding: 6px 10px;
              &:nth-last-child(1) {
                right: auto;
                left: 0;
              }
              &:nth-last-child(2) {
                right: auto;
                left: 48px;
                &::before {
                  left: auto;
                  right: -5px;
                  z-index: 6;
                  background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
                }
              }
            }
            &:hover {
              td {
                &:nth-last-child(2) {
                  &::before {
                    background: -moz-linear-gradient(left, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                    background: -webkit-linear-gradient(left, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                    background: linear-gradient(to right, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#00f5f5f5',GradientType=1 );
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.category_element {
  width: 100%;
}

.upload_form {
  .dropZoneArea {
    p {
      display: block;
      width: calc(100% - 32px);
      margin: 16px auto;
      overflow: hidden;
    }
    .MuiGrid-container {
      margin: 0;
      width: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      > div {
        padding: 16px;
        margin: 16px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
        > svg {
          padding: 10px;
        }
        button {
          top: -6px;
          right: -6px;
        }
      }
    }
  }
  .linearProgress {
    margin: 20px 0;
  }
  .wrapper_error {
    margin: 16px 0;
    &.first-child {
      margin-top: 0;
    }
    &.last-child {
      margin-bottom: 0;
    }
    .errorList {
      white-space: nowrap;
      overflow: auto;
      width: 100%;
      padding: 6px 0;
      max-height: 200px;
      border-top: 1px solid rgba(0, 0, 0, 0.25);
      > div {
        margin: 0 0 16px;
        &:last-child {
          margin: 0;
        }
        p {
          margin: 0;
          padding: 0;
          width: 100%;
          white-space: nowrap;
          &.red {
            color: #f00;
          }
        }
      }
    }
  }
}

[dir="rtl"] {
  .upload_form {
    .dropZoneArea {
      .MuiGrid-container {
        > div {
          button {
            top: -6px;
            right: auto;
            left: -6px;
          }
        }
      }
    }
  }
}

.table-responsive {
  .MuiButton-root {
    text-transform: none;
  }
  text-transform: capitalize;
  &.sticky-last-column {
    & th,
    & td {
      background-color: #fff;
    }
    & thead tr th:nth-last-child(2),
    & tbody tr td:nth-last-child(2) {
      position: static;
      max-width: 100%;
    }
  }
}

.flex-container {
  display: flex;
}

.version-footer {
  position: absolute;
  bottom: 0;
  left: 5px;
  font-size: 12px;
  line-height: normal;
  letter-spacing: -0.006em;
  white-space: nowrap;
}

.product_not_found {
  min-height: 100%;
  padding: 10px 0;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  .product_not_found_box {
    max-width: 500px;
    margin: 0 auto;
  }
  .banner {
    display: block;
    width: 100%;
    max-width: 162px;
    margin: 0 auto 20px;
    &:last-child {
      margin: 0 auto;
    }
    > span {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0 0 80.24691358024691%;
      background: transparent url("../svg/product_not_found.svg") no-repeat scroll 50% 50%;
      background-size: contain;
    }
  }
  p {
    display: block;
    width: 100%;
    margin: 20px 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.header {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #252c32;
      .red {
        color: #e02443;
      }
    }
  }
}

[data-texty] {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[data-texty-tooltip] {
  margin: 6px 0;
  padding: 4px 10px;
  background-color: rgba(97, 97, 97, 0.9);
  color: #fff;
  z-index: 99999;
  word-wrap: break-word;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  font-size: 0.625rem;
}

[data-texty-arrow] {
  position: absolute;
  bottom: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0;
  border-color: rgba(97, 97, 97, 0.9) transparent transparent;
}

[data-texty-arrow*="bottom"] {
  top: -6px;
  bottom: inherit;
  transform: rotate(180deg);
}

/* disable the builtin tooltip for truncated text on Safari */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @supports (not (-ms-ime-align: auto)) {
    .EllipsisText::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
    }
  }
}
